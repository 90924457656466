/* #root {
  height: 100%;
}

.App {
  height: 100%;
}

.ant-layout {
  height: 100vh;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-layout .ant-layout-sider-children {
  height: 100vh;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-menu-item {
  display: flex;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.ant-menu-vertical{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.ant-layout-content{
  overflow-y: auto !important;
  margin-top: unset !important;
}
.ant-layout .ant-layout-sider{
  max-width: unset !important;
  min-width: unset !important;
  width: unset !important;
}

.ant-menu-vertical  .ant-menu-submenu  .ant-menu-submenu-title{
  height: auto !important;
  line-height: 30px;
  font-size: 12px;
  margin-block: 0px !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-block: 0px !important;
}

.ant-menu-vertical  .ant-menu-item{
  padding-inline: unset;
  width: 100% !important;
}

.ant-menu-item{
  border-bottom: unset !important;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #ffffff;
  
  border-radius: 10px;


}

.ant-menu.ant-menu-dark .ant-menu-sub {

  border-radius: 10px;
}
.ant-menu-vertical.ant-menu-sub  .ant-menu-item{
  background: #ffffff;
  height: auto !important;
  padding-top:0 !important;
  line-height: 40px !important;

}
.ant-menu-vertical.ant-menu-sub :hover{
  background-color: #E3E8FC !important;
}


.ant-menu-dark .ant-menu-item{

  line-height: 23px !important;
  font-size: 12px;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.ant-form-item-label{
  padding: 0px !important;
}

.ant-form-item-label > label{
  color: #636980 !important;
  font-size: 16px;
  font-weight: 500
}
.ant-form-item{
  margin: 0px;
  margin-bottom: 5px;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
  color: #397ff4 !important;
  .ant-btn > span {
    display: inline-block;
    color: #397ff4;
    width: 100%;
    text-align: start;
  }
  .ant-upload.ant-upload-select {
    .ant-btn > span :focus{
    outline: none !important;
    }
}

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 40px !important;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: #397ff4;
  }
  .ant-upload-list-text .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-name {
    color: #397ff4;
  }
}
.ant-switch-checked {
  background-color: #03C83E !important;
}

.ant-switch-checked :focus{
  outline: none;
}
.ant-switch:focus,
.ant-switch:focus-visible,
.ant-switch:hover,
.ant-switch:active,
.ant-switch-checked:focus {
  outline: none !important;
  box-shadow: none !important;
}

.styled-cursor{
  cursor: pointer;
} */
.switch_button.ant-switch {
  border: none !important;
  outline: none !important;
}
.switch_button.ant-switch-checked {
  background-color: #03C83E !important;
  border: none !important;
  outline: none !important;
}

.upload_file_input.ant-upload-wrapper .ant-upload-select{
  width: 100%;
}
.upload_file_input.ant-upload-wrapper .ant-upload-select button{
  background-color: #E3E8FC !important;
}

.button_groups_style{
  color: #fff !important;
}
.button_groups_style:focus{
  color: #2D53DA !important;
  outline: none;
}
.button_groups_style:hover{
  color: #2D53DA !important;
  outline: none;

}

.cancel_button_style{
  color: #2D53DA;
  outline: none !important;
}

.number_input_style::-webkit-inner-spin-button, 
.number_input_style::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.number_input_style {
  -moz-appearance: textfield;
}

.country_select_number_field{
  border: 1px solid #d4d6db !important;
  border-radius: 5px;
}

.country_select_number_field .PhoneInputInput{
  border: 1px solid #e8e9ec !important;
  border-radius: 5px;
  border-color: unset;
}
.country_select_number_field .PhoneInputInput:focus{
  border: 1px solid #4096ff !important;
  /* border-radius: 5px; */
  border-color: unset;
  outline: none !important;
}

.country_select_number_field .PhoneInputInput:hover{
  border: 1px solid #4096ff !important;
  /* border-radius: 5px; */
  border-color: unset;
}

.cancel-button {
  background-color: white;
  border: 1px solid rgb(45, 83, 218);
  color: rgb(45, 83, 218);
  margin-right: 20px;
  width: 110px;
  outline: none !important;
}

.logout-button {
  background-color: rgb(45, 83, 218);
  color: white; 
  border: none; 
  width: 110px;
  outline: none !important;
}
.logout-button:hover {
  background-color: rgb(45, 83, 218) !important;
  color: white !important;
}
.logout-button:focus, 
.logout-button:active {
  border: 1px solid rgb(45, 83, 218) !important;
  background-color: white !important;
}